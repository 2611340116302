import { Button, Space, notification } from "antd";
import { ReactNode, useEffect, useState } from "react";
import Title from "antd/es/typography/Title";
import Text from "antd/es/typography/Text";
import { useSearchParams } from "react-router-dom";
import { KilnTypeUIRenderer, genRendererMap } from "../kiln_types/factory";
import { KilnType } from "../kiln_types/common";
import { Can } from "../../permissions";
import { KilnLogSummarySelectable } from "../widgets/datasets/kiln_log_summaries";

/** Component which displays some basic kiln details */
function KilnBasicInfoPanel(props: any) {

  var kilnid = props.kilnid;
  var kiln_type = props.kiln_type;
  var type : ReactNode = (<></>);
  if (kiln_type) {
    const renderer : KilnTypeUIRenderer = props.rendererMap.get(kiln_type as KilnType);

    type = renderer.genKilnTypeTag();
  }
  
  return (
    <>
    <Space size="small" direction="vertical">
      <Title level={2}>Kiln Detail</Title>
      <Space size="small" direction="horizontal">
        <Text>Kiln ID: </Text>
        <Text type="secondary">{kilnid}</Text>
        <Text>Type: </Text>
        {type}
      </Space>
    </Space>
    </>
  )
}

function KilnDetail(props : any) {

  const [kilnLogs, setKilnLogs] = useState<any>([]);
  const [kilnRegister, setKilnRegister] = useState<any>({});
  const [selectedRows, setSelectedRows] = useState<Array<number>>([]);
  const [searchParams] = useSearchParams();
  
  const kilnTypeRenderMap: Map<KilnType, KilnTypeUIRenderer> = genRendererMap();

  const input_kilnid : string|null = searchParams.get('id');

  useEffect(() => {
    const executeSearch = async () => {
      await fetch(`/api/kiln/registry/${input_kilnid}`, {
        headers: {
          'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
          },
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        notification.error({
          message: 'Server error querying kiln details.',
          description: 'Please try again later.',
          duration: 4,
          placement: "top",
        });
        return null;
      })
      .then(data => {
        if (data != null) {
          setKilnRegister(data.register);
          setKilnLogs(data.kiln_logs);
        }
        else {
          notification.error({
            message: 'Error querying kiln details.',
            description: 'No details available.',
            duration: 4,
            placement: "top",
          });
        }
      })
      .catch(err => {
        console.log("error " + err);
        notification.error({
          message: 'Error querying kiln details.',
          description: 'Please try again later.',
          duration: 4,
          placement: "top",
        });
      });
      
    }
    executeSearch();
  }, [input_kilnid])
  

  function cCapsuleWithAll() {
    cCapsuleWith(kilnLogs.map((l:any) => l.analysis_id));
  }

  function cCapsuleWithSelected() {
    cCapsuleWith(selectedRows);
  }

  function cCapsuleWith(analysis_ids: Array<number>) { 
    console.log("Let's cCapsule!");
  }

  return (
  <div className="wrapper">
    <Space size="small" direction="vertical">
      <KilnBasicInfoPanel 
        kiln_type={kilnRegister.type} 
        rendererMap={kilnTypeRenderMap}
        kilnid={input_kilnid}/>
      <KilnLogSummarySelectable
        title="Logs Eligible for C-Capsule Submission"
        logs={kilnLogs}
        showKilnId={false}
        setSelectedRows={setSelectedRows}
        buttonPane={(
          <Can I='create' a='capsule'>
            <Space size="small" direction="horizontal">
              <Button type="primary" onClick={cCapsuleWithAll}>Submit All</Button>
              <Button type="dashed" onClick={cCapsuleWithSelected} disabled={selectedRows.length === 0}>Submit Selected</Button>
            </Space>
          </Can>
        )}/>      
    </Space>
  </div>
  );
}

export default KilnDetail;